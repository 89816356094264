import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CiEdit } from "react-icons/ci";
import dayjs from 'dayjs';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import { IoIosAddCircleOutline } from "react-icons/io";
import OrderItemDetialModal from '../Modal/DetialModal/OrderItemDetialModal';
import CommentModal from '../Modal/Uttils/CommentModal';
import { LiaCommentsSolid } from "react-icons/lia";
import { BsLayerBackward } from "react-icons/bs";
import DeleteModal from '../Modal/Uttils/DeleteModal';
import axios from 'axios';

export default function OrderItemListTable({ orders, drivers, edit, itemname, filteruser }) {
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [isMore, setIsMore] = React.useState(false);
    const [orderItems, setOrderItems] = React.useState([]);
    const [comment, setComment] = React.useState('');
    const [commentModal, setCommentModal] = React.useState(false);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [id, setId] = React.useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeModal = () => {
        setIsMore(false);
        setCommentModal(false);
        setIsUpdate(false);
    }

    const sureBackItem = () => {
        axios.put('https://turboapi.templateapi.xyz/order/back/' + id, {
            isBack: true,
        })
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e))
    }

    return (
        <>
            {isUpdate && id ? <DeleteModal
                closeModal={closeModal}
                click={sureBackItem}
                text={'Та буцаагдсан барааг агуулахад хүлээн авсан уу?'} />
                : null}
            {commentModal ? <CommentModal closeModal={closeModal} text={comment} /> : <></>}
            {isMore ? <OrderItemDetialModal style={{ maxHeight: '75vh', overflowY: 'auto' }} orders={orderItems} closeModal={closeModal} /> : <></>}
            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                            {/* <TableCell className='table-title' style={{ color: '#fff', width: '20px' }}></TableCell> */}
                            <TableCell className='table-title' style={{ color: '#fff', width: '20px' }}>Id</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }}>Утас / Хаяг</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '100px' }}>Харилцагч</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '250px' }} align="center">Барааны нэр</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '250px' }} align="center">Тоо ширхэг</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Төлөв</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Нийт дүн</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үүсгэсэн огноо</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл хийсэн огноо</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '250px' }} align="center">Жолооч</TableCell>
                            {/* <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл</TableCell> */}
                            {/* <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Буцааж авсан</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders
                            .filter((e) => filteruser ? (e.user && e.user.includes(filteruser)) : e)
                            .slice(!itemname ? page * rowsPerPage : 0, !itemname ? page * rowsPerPage + rowsPerPage : orders.length)
                            .map((row, index) => (
                                row.order
                                    .filter((e) => itemname ? e.name.toLowerCase().includes(itemname) : e)
                                    .map((e) => (
                                        <>
                                            <TableRow style={{ background: row.status === 'Хэсэгчлэн хүргэсэн' ? 'pink' : '#fff' }} className='hover' key={index}>
                                                {/* <TableCell className='table-item' align="left">
                                                <IoIosAddCircleOutline cursor={'pointer'} size={20}
                                                    onClick={() => {
                                                        setOrderItems(row.order)
                                                            ; setIsMore(true)
                                                    }}
                                                />
                                            </TableCell> */}
                                                <TableCell className='table-item' align="left">{index + 1}</TableCell>
                                                <TableCell className={
                                                    row.status === 'Хойшлуулсан' || row.status === 'Маргааш авах' ? 'isFuture table-item' : 'table-item'
                                                } component="th" scope="row">
                                                    {row.phone} <br /> {row.address}
                                                </TableCell>
                                                <TableCell className='table-item' align="center">{
                                                    // user.filter((e) => e._id.includes(row.user)).map((e) => (
                                                    //   e.name
                                                    // ))
                                                    row.shop
                                                }</TableCell>
                                                <TableCell className='table-item' align="center">
                                                    {e.name}
                                                </TableCell>
                                                <TableCell className='table-item' align="center">
                                                    {e.unit}
                                                </TableCell>
                                                <TableCell className='table-item' align="center">
                                                    <div className='line-start'>
                                                        {row.comment ? <LiaCommentsSolid onClick={() => {
                                                            setCommentModal(true);
                                                            setComment(row.comment);
                                                        }}
                                                            size={20} color='red' cursor={'pointer'} /> : <></>}
                                                        <p className={row.status === 'Хүлээгдэж байна' ? 'green-border' :
                                                            row.status === 'Жолооч авсан' ? 'blue-border' :
                                                                row.status === 'Хүргэсэн' ? 'full-green' :
                                                                    row.status === 'Хаягаар очсон' ? 'full-red' :
                                                                        row.status === 'Маргааш авах' ? 'full-blue' :
                                                                            row.status === 'Хойшлуулсан' ? 'full-yellow'
                                                                                : row.status === 'Буцаагдсан' ? 'red-border'
                                                                                    : 'red-border'}>
                                                            {row.status}
                                                        </p>
                                                    </div>
                                                    {row.isBack ? <p className='back'>Буцаалтыг хүлээж авсан</p> : null}
                                                </TableCell>
                                                <TableCell className='table-item' align="center">{new Intl.NumberFormat('en-Us').format(row.price)}</TableCell>
                                                <TableCell className='table-item' style={{ width: '100px' }} align="center">
                                                    {dayjs(row.createdAt).format('YYYY-MM-DD')}
                                                </TableCell>
                                                <TableCell className='table-item' style={{ width: '100px' }} align="center">
                                                    {
                                                        dayjs(row.orderDate).subtract(8, 'hour').format('YYYY-MM-DD')
                                                    }</TableCell>
                                                <TableCell className='table-item' align="center">
                                                    {drivers.filter((e) => e._id.includes(row.driver)).map((e) => (
                                                        <p>{e.name}</p>
                                                    ))}
                                                </TableCell>
                                                {/* <TableCell className='table-item' align="center">
                                                <CiEdit
                                                    onClick={() => edit({ id: row._id })}
                                                    size={30} style={{ cursor: 'pointer' }} color='blue'
                                                />
                                            </TableCell>
                                            <TableCell className='table-item' align="center">
                                                <BsLayerBackward size={25} color='green'
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setId(row._id);
                                                        setIsUpdate(true);
                                                    }}
                                                />
                                            </TableCell> */}
                                            </TableRow >
                                        </>
                                    ))

                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={itemname ? orders.map((e) => (
                        e.name === itemname
                    )).length : orders.length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer >
        </>
    );
}
