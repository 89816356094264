import React, { useEffect, useState } from 'react'
import styles from './Shops.module.css';
import Navbar from '../../Components/Navbar/Navbar';
import ShopsTable from '../../Utills/Table/ShopsTable';
import ShopsAddModal from '../../Utills/Modal/ShopsAddModal';
import axios from 'axios';
import add_icon from '../../Assets/uttils/add_button.svg'

const Shops = () => {
  const [isModal, setModal] = useState(false);
  const [load, setLoad] = useState(true);
  const [shops, setShops] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState('');

  const closeModal = () => {
    setModal(false);
  }

  useEffect(() => {
    window.localStorage.setItem('side-id', 6);

    if (load) {

      axios.get('https://turboapi.templateapi.xyz/user?role=user')
        .then((e) => {
          setUsers(e.data.data);
          setLoad(false)
        })
        .catch((e) => console.log(e));
    }
  }, [load])
  return (
    <>
      {!isModal ? <Navbar title={'Дэлгүүр'} /> : <></>}
      {isModal ? <ShopsAddModal closeModal={closeModal} /> : <></>}
      {load ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className="space-between" style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
          <button onClick={() => setModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
          <label>
            Дэлгүүрээр хайх:
            <select onChange={(e) => setUser(e.target.value)} name="" id="">
              <option value="">Сонгох</option>
              {users.map((e) => (
                <option value={e._id}>{e.shop}</option>
              ))}
            </select>
          </label>
        </div>
        <ShopsTable shops={users.filter((e) => user ? e._id === user : e)} />
      </div>
    </>
  )
}

export default Shops
