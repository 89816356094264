import axios from 'axios';
import React, { useEffect, useState } from 'react'
import styles from './Accounting.module.css'

const Accounting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (isLoading) {
      axios.get('https://turboapi.templateapi.xyz/container?sort=-createdAt')
        .then((e) => { setOrders(e.data.data); setIsLoading(false) })
        .catch((e) => console.log(e));
    }
  }, [isLoading]);

  const changeStatus = ({ id, reportId }) => {
    // alert(status);
    axios.put('https://turboapi.templateapi.xyz/container/accounting/' + id + '/' + reportId, {
      status: status
    })
      .then(() => alert('s'))
      .catch((e) => alert(e));
  }

  return (
    <div className={styles.container}>
      {orders.map((e) => e.item.map((order) => (
        <div className='modal'>
          <h4>{order.reportId} +{order.name} + / {order.status} /</h4>
          <h4>{order.unit} /</h4>
          <select onChange={(e) => setStatus(e.target.value)}>
            {/* enum: ['Хүлээгдэж байна', 'Хүлээж авсан', 'Цуцалсан', 'Зарлага'], */}
            <option value="">Choose</option>
            <option value="Хүлээж авсан">Хүлээж авсан</option>
            <option value="Цуцалсан">Цуцалсан</option>
            <option value="Зарлага">Зарлага</option>
          </select>
          <button onClick={() => changeStatus({ id: order._id, reportId: order.reportId })} className='button-white'>save</button>
        </div>
      )))}
    </div>
  )
}

export default Accounting
