import React, { useEffect, useState } from 'react'
import styles from './Container.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import ContainerAddModal from '../../Utills/Modal/ContainerAddModal'
import axios from 'axios'
import MinusContainerModal from '../../Utills/Modal/MinusModal/MinusContainerModal'
import ContainerOfPackageTable from '../../Utills/Table/ContainerOfPackageTable'

const ContainerOfPackage = () => {
    const [isModal, setIsModal] = useState(false);
    const [minusModal, setMinusModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [containers, setContainers] = useState([]);
    const [users, setUsers] = useState([]);
    const [shops, setShops] = useState([]);
    const [user, setUser] = useState('');
    const [status, setStatus] = useState('');
    const [areacontainers, setAreaContainers] = useState([]);

    const closeModal = () => {
        setIsModal(false);
        setMinusModal(false);
    }

    useEffect(() => {
        window.localStorage.setItem('side-id', 3);
        if (isLoading) {
            axios.get('https://turboapi.templateapi.xyz/container?sort=-createdAt')
                .then((e) => {
                    setIsLoading(false);
                    setContainers(e.data.data);
                })
                .catch((e) => alert(e));

            axios.get('https://turboapi.templateapi.xyz/user')
                .then((e) => {
                    setIsLoading(false);
                    setUsers(e.data.data);
                })
                .catch((e) => alert(e));

            // axios.get('https://turboapi.templateapi.xyz/shop')
            //     .then((e) => {
            //         setIsLoading(false);
            //         setShops(e.data.data.filter((e) => e.visible !== 'true'));
            //     })
            //     .catch((e) => alert(e));

            axios.get('https://turboapi.templateapi.xyz/areacontainer')
                .then((e) => {
                    setAreaContainers(e.data.data);
                })
                .catch((e) => alert(e));
        }
    }, [isLoading]);

    return (
        <>
            {!isModal ? <Navbar title={'Агуулах бараа'} /> : <></>}
            {isModal ? <div className='abs'><ContainerAddModal closeModal={closeModal} /></div> : <></>}
            {minusModal ? <div className='abs'><MinusContainerModal closeModal={closeModal} /></div> : <></>}
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            <div className={styles.container}>
                <div className='line-start'>
                    {/* <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
                    <button onClick={() => setMinusModal(true)} className='button'>Бараа хасах</button> */}
                    <label htmlFor="">
                        Дэлгүүрээр шүүх
                        <select name="" id="" onChange={(e) => setUser(e.target.value)}>
                            <option value="">Сонгох</option>
                            {users.map((e) => (
                                <option value={e._id}>{e.shop}</option>
                            ))}
                        </select>
                    </label>
                    <label htmlFor="">
                        Статусаар шүүх:
                        <select name="" id="" onChange={(e) => setStatus(e.target.value)}>
                            <option value="">Сонгох</option>
                            <option value="Хүлээж авсан">Хүлээж авсан</option>
                            <option value="Хүлээгдэж байна">Хүлээгдэж байна</option>
                            <option value="Цуцалсан">Цуцалсан</option>
                            <option value="Зарлага">Зарлага</option>
                        </select>
                    </label>
                </div>
                <ContainerOfPackageTable areacontainers={areacontainers} containers={containers} filterStatus={status} user={user} users={users} shops={shops} />
            </div>
        </>
    )
}

export default ContainerOfPackage
