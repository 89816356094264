import axios from 'axios';
import React, { useState } from 'react'

const AddDepositModal = ({ closeModal, orderDate, user, shops }) => {
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');

    const addDeposit = () => {
        axios.post('https://turboapi.templateapi.xyz/deposit', {
            name: name,
            date: orderDate,
            amount: amount,
            user: user,
        })
            .then(() => window.location.reload(false))
            .catch((e) => e)
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Зарлага - {
                        shops.filter((e) => user ? user === e.user : '')
                            .map((e) => (
                                e.name
                            ))
                    } - / {orderDate} /
                    </h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Зарлагын хэмжээ:
                    <input type="number" onChange={(e) => setAmount(e.target.value)} value={amount} />
                </label>
                <div className='button_container'>
                    <button onClick={addDeposit} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default AddDepositModal
