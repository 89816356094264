import axios from 'axios';
import React, { useState } from 'react'

const FinanceSuccess = ({ closeModal, orderDate, user, shops }) => {
    const [success, setSuccess] = useState(false);

    const successDeposit = () => {
        axios.post('https://turboapi.templateapi.xyz/depositsuccess', {
            // name: orderDate,
            date: orderDate,
            user: user,
            status: success,
        })
            .then(() => window.location.reload(false))
            .catch((e) => alert(e))

        // .then(() => {})
    }

    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Тооцоо нийлсэн эсэх - {
                        shops.filter((e) => user ? user === e.user : '')
                            .map((e) => (
                                e.name
                            ))
                    } - / {orderDate} /</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <select onChange={(e) => setSuccess(e.target.value)} name="" id="">
                    <option value={''}>Сонгох</option>
                    <option value={true}>Тооцоо нийлсэн</option>
                    <option value={false}>Тооцоо нийлээгүй</option>
                </select>
                <div className='button_container'>
                    <button onClick={successDeposit} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default FinanceSuccess
