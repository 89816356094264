import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import dayjs from 'dayjs';

export default function ReportContainerTable({ report, endDate, startDate, user, users, shops, orders, container }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  const calculateTotalDownload = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      // .filter((e) => e.canceled === false)
      // .filter((e) => e.isSaved === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name === name)
            .filter((el) => el.status === 'Хүлээж авсан')
            .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal +
                (
                  ((dayjs(item.date).isBefore(startDate) || dayjs(item.date).isSame(startDate, 'date'))
                    &&
                    (dayjs(item.date).isAfter(endDate) || dayjs(item.date).isSame(endDate, 'date')))
                    // || (dayjs(item.date).isSame(endDate, 'date') && dayjs(item.date).isSame(startDate, 'date'))
                    ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalDownloadBalance = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      // .filter((e) => e.canceled === false)
      // .filter((e) => e.isSaved === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name === name)
            .filter((el) => el.status === 'Хүлээж авсан')
            .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + item.unit,
              // (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(dayjs(endDate)) ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalOrdersBalance = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal + item.unit, 0);
      }, 0);
  };

  const calculateTotalDelivered = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .filter((e) => e.status === 'Хүргэсэн' || e.status === 'Хэсэгчлэн хүргэсэн')
      .filter((e) => {
        return ((dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate) || dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date')) &&
          (dayjs(e.orderDate).subtract(8, 'hour').isAfter(endDate) || dayjs(e.orderDate).subtract(8, 'hour').isSame(endDate, 'date')))
      })
      .reduce((total, items) => {
        return total +
          items.order
            .filter((e) => {
              return e.name === name
            })
            .reduce((itemTotal, item) => itemTotal +
              item.unit, 0);
      }, 0);
  };

  const calculateTotalCancleled = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              ((dayjs(report.orderDate).isBefore(dayjs().add(8, 'hours'))
                // ||
                // dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date')
              )
              ) ?
                item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalRemoved = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      // .filter((e) => e.isSaved === false)
      .reduce((total, report) => {
        return total + report.item
          .filter((e) => {
            return e.name === name
          })
          .filter((el) => el.status === 'Зарлага')
          .filter((el) => el.switchsuccess === true)
          .reduce((itemTotal, item) => itemTotal +
            (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
              || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'dates')
              ? item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalOrdersBalanceFilter = ({ name, userContainer, isFilter }) => {
    console.log(endDate.format('YYYY-MM-DD') + '---' + startDate.format('YYYY-MM-DD'));
    return orders
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              (
                dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate.add(8, 'hour')) ||
                dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate.add(8, 'hour'), 'date')
              )
                ? item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalOrders = ({ name, userContainer, isFilter }) => {
    // console.log(endDate.format('YYYY-MM-DD') + '---' + startDate.format('YYYY-MM-DD'));
    return orders
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              (
                ((dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate.add(8, 'hours'))
                  || dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate.add(8, 'hours'), 'date')) &&
                  (dayjs(report.orderDate).subtract(8, 'hour').isAfter(endDate)
                    || dayjs(report.orderDate).subtract(8, 'hour').isSame(endDate, 'date')))
              )
                ? item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalDownloadBalanceFilter = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      // .filter((e) => e.isSaved === false)
      // .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name === name)
            .filter((el) => el.status === 'Хүлээж авсан')
            .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + (
                  (dayjs(item.date).isBefore(startDate) ||
                    dayjs(item.date).isSame(startDate, 'date')
                  ) ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalCancleledFilter = ({ name, userContainer }) => {
    console.log(dayjs())

    return orders
      .filter((e) => userContainer === e.user)
      .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
      .filter((e) => dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate) ||
        dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name === name)
          .reduce((itemTotal, item) => itemTotal +
            (
              ((dayjs(report.orderDate).isBefore(dayjs().add(8, 'hours'))
                // ||
                // dayjs(report.orderDate).add(8, 'hours').isSame(dayjs().add(8, 'hour'), 'date')
              )
              ) ?
                item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalRemovedFilter = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      // .filter((e) => e.isSaved === false)
      .reduce((total, report) => {
        return total + report.item
          .filter((e) => {
            return e.name === name
          })
          .filter((el) => el.status === 'Зарлага')
          .filter((el) => el.switchsuccess === true)
          // .filter((el) => dayjs(el.date))
          .filter((e) => dayjs(e.date).subtract(8, 'hour').isBefore(startDate) ||
            dayjs(e.date).subtract(8, 'hour').isSame(startDate, 'date'))
          .reduce((itemTotal, item) => itemTotal +
            (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
              || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'date')
              ? item.unit : 0), 0);
      }, 0);
  };

  return (
    <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
      <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#2c9961', color: '#fff' }}>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Харилцагч</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Барааны нэр</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Татан авалт</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Хүргэгдсэн</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Шүүлттэй үлдэгдэл</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Одоогын үлдэгдэл</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Нийт захиалга</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {report
            // .sort((a, b) => {
            //   const balanceA = calculateTotalDownloadBalance({
            //     name: a.title,
            //     userContainer: a.user
            //   }) -
            //     calculateTotalOrdersBalance({ name: a.title, userContainer: a.user }) +
            //     calculateTotalCancleled({ name: a.title, userContainer: a.user }) -
            //     calculateTotalRemoved({ name: a.title, userContainer: a.user });

            //   const balanceB = calculateTotalDownloadBalance({
            //     name: b.title,
            //     userContainer: b.user
            //   }) -
            //     calculateTotalOrdersBalance({ name: b.title, userContainer: b.user }) +
            //     calculateTotalCancleled({ name: b.title, userContainer: b.user }) -
            //     calculateTotalRemoved({ name: b.title, userContainer: b.user });

            //   return balanceB - balanceA;
            // })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e, index) => (
              <TableRow className='hover'
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell component="th" scope="row">{
                  users.filter((el) => el._id === e.user).map((el) => (
                    <p>{el.shop}</p>
                  ))
                }</TableCell>
                <TableCell component="th" scope="row">{e.secondtitle ? e.secondtitle : e.title}</TableCell>
                <TableCell align="center">{
                  // ((e.secontCacheDownload ? e.secontCacheDownload : 0)) +
                  calculateTotalDownload({ name: e.title, userContainer: e.user })}
                </TableCell>
                <TableCell align="center">{
                  // ((e.secontCacheDelivered ? e.secontCacheDelivered : 0)) +
                  calculateTotalDelivered({ name: e.title, userContainer: e.user })
                }</TableCell>
                <TableCell align="center">{
                  ((e.defaultbalance ? e.defaultbalance : 0)
                  )
                  +
                  (
                    calculateTotalDownloadBalanceFilter({ name: e.title, userContainer: e.user }) -
                    calculateTotalOrdersBalanceFilter({ name: e.title, userContainer: e.user }) +
                    calculateTotalCancleledFilter({ name: e.title, userContainer: e.user }) -
                    calculateTotalRemovedFilter({ name: e.title, userContainer: e.user })
                  )
                }
                </TableCell>
                <TableCell align="center">{
                  // + (e.secontCacheBalance ? e.secontCacheBalance : 0)
                  // + (e.secontCacheBalance ? e.secontCacheBalance : 0)
                  (
                    (e.defaultbalance ? e.defaultbalance : 0)) +
                  // calculateTotalDownloadBalance({ name: e.title, userContainer: e.user })
                  (calculateTotalDownloadBalance({ name: e.title, userContainer: e.user }) -
                    calculateTotalOrdersBalance({ name: e.title, userContainer: e.user }) +
                    calculateTotalCancleled({ name: e.title, userContainer: e.user }) -
                    calculateTotalRemoved({ name: e.title, userContainer: e.user }))
                }
                </TableCell>
                <TableCell align="center">{
                  // (
                  // (e.secontCacheOrder ? e.secontCacheOrder : 0)) +
                  calculateTotalOrders({ name: e.title, userContainer: e.user })
                }</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={report
          .length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}